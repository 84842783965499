import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
type Props = {
  block: Payload.FullWidthUGCImages;
};
export const FullWidthUGCImagesServer = ({
  block
}: Props) => <ContentBlockServer className="py-10 mb-4 text-gray-900" data-sentry-element="ContentBlockServer" data-sentry-component="FullWidthUGCImagesServer" data-sentry-source-file="FullWidthUGCImages.tsx">
    <h2 className="px-8 mb-6 text-3xl font-bold text-center lg:text-3xl">
      {block.headline}
    </h2>
    <ul className="grid grid-cols-2 md:grid-cols-4">
      {block.ugcImages?.map(item => <li key={`ugc-item-${item.id}`} className="w-full list-none aspect-square" role="img" aria-label={item.image.alt ?? "Product image"} style={{
      background: `url('${item.image.url}') `,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    }} />)}
    </ul>
  </ContentBlockServer>;